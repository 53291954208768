
import RoleModal from './RoleModal'

export default {
    components: {
        RoleModal
    },
    data() {
        return {            
            total: 0,
            items: [],            

            searchQuery: {},
            selected_org: null,
            organizations: [],
            
            roleModalKey: 0,
            customPagination: true
        }
    },
    mounted() {
        if (false){
            this.fetchOrgMeta()
        }
    },
    computed: {
        header() {
            var arr = [
                {
                    label: 'Role Name',
                    name: 'name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Organization',
                    name: 'org_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Type',
                    name: 'org_type',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right',
                }
            ]

            if (false) {
                return arr
            } else {
                return arr.filter( x => {
                    if (!['org_name', 'org_type'].includes(x.name) ) {
                        return x
                    }
                })
            }             
        }
    },
    methods:{
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData()
        },
        addMoreClick() {
            this.roleModalKey += 1
            this.$nextTick(()=>{
                this.$refs.roleModal.forceRerenderInsert(this.selected_org)
            })
        },  
        updateRole(payload, e = null) {
            let formType = 'UPDATE'
            this.roleModalKey += 1
            if(payload.is_outbox_role){
                formType = 'VIEW'
            }
            this.$nextTick(()=>{
                this.$refs.roleModal.forceRerenderUpdate(payload.id, payload.org_type,formType)
            })
            if (e) {
                e.stopPropagation()
            }
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        // fetchData() {
        //     roleServices.findRole({
        //         ...this.searchQuery,
        //         sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
        //         sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
        //         organizationId: this.selected_org ? this.selected_org.id : null
        //     }).then(resp=>{
        //         if(!resp.error) {
        //             this.items = resp.data.d.c
        //             this.total = resp.data.d.t
        //         }
        //     })
        // },
        // fetchOrgMeta() {
        //     orgServices.findMeta().then(resp=>{
        //         if(!resp.error) {
        //             this.organizations = resp.data.d
        //         }
        //     })
        // },
        // deleteRole(item, e) {
        //     this._showConfirm('You are about to delete this role, this cannot be undone.').then(confirm=>{
        //         if(confirm) {
        //             roleServices.deleteRole(item.id).then(resp=>{
        //                 if (!resp.error) {
        //                     this.onCompleted('UPDATE')
        //                 }
        //             })
        //         }
        //     })            
        //     e.stopPropagation()
        // }
    }
}